<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8">
    <v-form class="tw-py-4" v-model="valid" @submit.prevent="onCalculate" ref="form">
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Surname</label>
            <v-text-field
              type="text"
              name="surname"
              id="surname"
              v-model="form.surname"
              :rules="[required('Surname')]"
              required
            ></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Other Names</label>
            <v-text-field
              type="text"
              name="othernames"
              id="othernames"
              v-model="form.othernames"
              :rules="[required('Other Name(s)')]"
              required
            ></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Email Address</label>
            <v-text-field
              type="text"
              name="email"
              id="email"
              v-model="form.email"
              :rules="[required('Email Address'), emailFormat()]"
              required
            ></v-text-field>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-flex-col lg:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Date of Birth</label>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.dateOfBirth"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[required('Date of Birth')]"
                ></v-text-field>
              </template>
              <v-date-picker
                id="dateOfBirth"
                :active-picker.sync="activePicker"
                v-model="form.dateOfBirth"
                no-title
                @input="menu = false"
                :max="givenYearsAgo(18)"
                :min="givenYearsAgo(65)"
                color="primary"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Term in Years</label>
            <v-select
              id="term"
              v-model="form.term"
              :items="getTerms"
              dense
              :rules="[requiredNumber('Term in Years')]"
            ></v-select>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">{{ amountTitle }}</label>
            <v-select
              id="amount"
              name="amount"
              v-model="amount"
              item-text="title"
              :items="sumAssuredList"
              dense
              :rules="[requiredNumber('Sum Assured')]"
            ></v-select>
            <small v-if="switch1" class="c-yellow-text tw-italic">
              <v-icon size="14" color="secondary">mdi-information-outline</v-icon>Minimum sum
              assured is KES. 100,000</small
            >
          </div>
          <div class="tw-px-2 lg:tw-px-6 tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Payment Frequency</label>
            <v-select
              id="freqOfPayment"
              v-model="form.freqOfPayment"
              :items="payFreq"
              item-text="label"
              item-value="value"
              dense
              :rules="[required('Payment Frequency')]"
            ></v-select>
          </div>
        </div>
        <div class="tw-mt-5">
          <div class="px-5">
            <h2 class="tw-text-xl tw-font-bold">Expand Your Coverage</h2>
            <p class="mt-2">
              Please list the members of your family you wish to include under Pumzisha Cover i.e.
              parents, parents-in-law and children(maximum age of parents and parents-in-law is 69
              years, whilst maximum age for children is 25 years.)
            </p>
          </div>
          <div class="tw-mt-10">
            <v-stepper v-model="el" alt-labels class="tw-px-4 md:tw-px-20 lg:tw-px-36">
              <v-stepper-header class="tw-px-2 md:tw-px-20 lg:tw-px-40">
                <v-stepper-step :complete="el > 1" step="1"> Spouse </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="el > 2" step="2"> Child </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3"> Parent </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12">
                    <v-card-text>
                      <div v-for="(spouse, index) in spouseArray" :key="index" class="mb-6">
                        <p class="tw-text-black tw-text-lg">Spouse {{ index + 1 }}</p>
                        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                          <div class="tw-w-full md:tw-w-1/2">
                            <p class="tw-text-sm c-blue-text">
                              Do you have a spouse that you wish to cover?
                            </p>
                            <v-select
                              v-model="spouseSelection[index]"
                              placeholder="e.g Yes / No"
                              class="tw-w-full"
                              :items="selectOptions"
                              :rules="[required('Spouse')]"
                            ></v-select>
                          </div>
                          <div v-if="spouseSelection[index] === 'Yes'" class="md:tw-w-1/2">
                            <p class="tw-text-sm c-blue-text">Date of Birth?</p>
                            <v-menu
                              v-model="spouseMenu[index]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template #activator="{ on, attrs }">
                                <v-text-field
                                  :value="spouseAges[index]"
                                  persistent-hint
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[required('Date of Birth')]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                id="spouseDob"
                                :active-picker.sync="activePicker"
                                v-model="spouseAges[index]"
                                no-title
                                @input="spouseMenu[index] = false"
                                :max="givenYearsAgo(18)"
                                :min="givenYearsAgo(69)"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <div class="tw-flex tw-justify-center">
                    <v-btn @click="el = 2" color="primary" width="100"> Next </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12">
                    <v-card-text class="tw-grid tw-grid-rows-2">
                      <div>
                        <p class="tw-text-black tw-text-lg">Child(s)</p>
                        <h2 class="tw-text-sm c-blue-text">
                          Do you have any <span class="tw-font-bold">Child(s)</span> that you wish
                          to cover?
                        </h2>
                        <v-select
                          v-model="childSelection"
                          :rules="[required('Child')]"
                          :items="selectOptions"
                          placeholder="e.g Yes / No"
                          class="tw-w-1/4"
                        ></v-select>
                      </div>
                      <div class="tw--mt-4" v-if="childSelection === 'Yes'">
                        <p class="tw-text-black tw-text-lg">Child(s) Date of Birth</p>
                        <p>Please provide the date of birth of each child.</p>
                        <div
                          class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4"
                        >
                          <div v-for="(child, index) in childrenArray" :key="index">
                            <label class="tw-text-sm c-blue-text">Child({{ index + 1 }})</label>
                            <v-menu
                              v-model="menus[index]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template #activator="{ on, attrs }">
                                <v-text-field
                                  :value="children[index]"
                                  persistent-hint
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tw-w-full sm:tw-w-40"
                                  :rules="[validateParentSelection]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                id="dateOfBirth"
                                :active-picker.sync="activePicker"
                                v-model="children[index]"
                                no-title
                                @input="menus[index] = false"
                                :max="givenYearsAgo(0)"
                                :min="givenYearsAgo(25)"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <div class="tw-flex tw-justify-center">
                    <v-btn class="tw-mr-4" @click="el = 1" color="primary" width="100" outlined>
                      Previous
                    </v-btn>
                    <v-btn color="primary" @click="el = 3" width="100"> Next </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card class="mb-12">
                    <v-card-text class="tw-grid tw-grid-rows-2">
                      <div>
                        <p class="tw-text-black tw-text-lg">Parent(s)</p>
                        <h2 class="tw-text-sm c-blue-text">
                          Do you have any <span class="tw-font-bold">Parent(s)</span> that you wish
                          to cover?
                        </h2>
                        <v-select
                          v-model="parentSelection"
                          :rules="[required('Parents')]"
                          :items="selectOptions"
                          placeholder="e.g Yes / No"
                          class="tw-w-1/4"
                        ></v-select>
                      </div>
                      <div class="tw--mt-4" v-if="parentSelection === 'Yes'">
                        <p class="tw-text-black tw-text-lg">Parent(s) Date of Birth</p>
                        <p>
                          Please provide the age bracket of each <b>parent</b> or<b>
                            parent-in-law</b
                          >
                          where applicable.
                        </p>
                        <div
                          class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4"
                        >
                          <div v-for="(parents, index) in parentArray" :key="index">
                            <label class="tw-text-sm c-blue-text">{{ parents }}</label>
                            <v-menu
                              v-model="parentMenu[index]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template #activator="{ on, attrs }">
                                <v-text-field
                                  :value="parent[index]"
                                  persistent-hint
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tw-w-full sm:tw-w-40"
                                  :rules="[validateParentSelection]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                id="dateOfBirth"
                                :active-picker.sync="activePicker"
                                v-model="parent[index]"
                                no-title
                                @input="parentMenu[index] = false"
                                :max="givenYearsAgo(40)"
                                :min="givenYearsAgo(69)"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <div class="tw-flex tw-justify-center">
                    <v-btn class="tw-mr-4" @click="el = 2" color="primary" width="100" outlined>
                      Previous
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
        </div>
        <div class="tw-w-full tw-px-2 lg:tw-px-6 tw-flex tw-justify-center tw-mt-3 md:tw-mt-6">
          <v-btn
            color="secondary"
            :loading="loading"
            :disabled="!isParentSelectionValid || !isChildrenSelectionValid || !valid"
            type="submit"
            >Calculate</v-btn
          >
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import validations from '@/utils/validations';
import { age, givenYearsAgo } from '@/utils/time';

export default {
  name: 'ClientQuotation',
  props: ['productData', 'terms'],
  data() {
    return {
      el: 1,
      step: 1,
      dateFormatted: '',
      menu: false,
      time: null,
      menu2: false,
      valid: false,
      loading: false,
      form: {
        name: '',
        surname: '',
        othernames: '',
        email: '',
        freqOfPayment: 'A',
        benefits: [],
        optionCode: '',
        dependents: [],
        term: '',
      },
      termsValue: null,
      ...validations,
      options: {
        locale: 'en',
        prefix: 'KES',
        suffix: '',
        precision: 0,
      },
      amountTitle: 'Sum Assured',
      switch1: true,
      amount: null,
      payFreq: [
        { label: 'Annually', value: 'A' },
      ],
      sumAssuredList: [
        { title: 'KES 100, 000', value: 100000 },
        { title: 'KES 200, 000', value: 200000 },
        { title: 'KES 300, 000', value: 300000 },
        { title: 'KES 500, 000', value: 500000 },
      ],
      termsArray: [
        { label: '5 Years', value: 5 },
        { label: '10 Years', value: 10 },
      ],
      ageBrackets: [
        { title: 'Age 23 - 29', value: 23 },
        { title: 'Age 30 - 34', value: 30 },
        { title: 'Age 35 - 39', value: 35 },
        { title: 'Age 40 - 44', value: 40 },
        { title: 'Age 45 - 49', value: 45 },
        { title: 'Age 50 - 54', value: 50 },
        { title: 'Age 55 - 59', value: 55 },
        { title: 'Age 60 - 64', value: 60 },
      ],
      selectOptions: ['Yes', 'No'],
      spouseSelection: ['No', 'No'],
      spouseObjects: [],
      childSelection: 'No',
      parentSelection: 'No',
      ageValue: null,
      childrenNo: null,
      parentsAges: [],
      menus: [],
      spouseMenu: [],
      parentMenu: [],
      children: [],
      spouse: [],
      parent: [],
      childrenArray: ['First', 'Second', 'Third', 'Forth'],
      parentArray: ['Father', 'Mother', 'Father', 'Mother'],
      activePicker: 'YEAR',
      spouseArray: ['First', 'Second'],
      parents: [],
      spouseAges: [],
      childAges: [],
      parentAges: [],
    };
  },
  watch: {
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    menus() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    spouseMenu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    parentMenu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  mounted() {},
  computed: {
    isParentSelectionValid() {
      return this.parentSelection !== 'Yes' || this.parent.length > 0;
    },
    isChildrenSelectionValid() {
      return this.childSelection !== 'Yes' || this.children.length > 0;
    },
    sumAssured: {
      get() {
        return this.switch1 ? this.amount : 0;
      },
    },
    ...mapState({
      agentCode: (state) => (state.auth.userInfo ? state.auth.userInfo.agnCode : 0),
    }),
    premium: {
      get() {
        return this.switch1 ? 0 : this.amount;
      },
    },
    getTerms() {
      return this.terms?.map((i) => (i.years ? i.years : i));
    },
    getTerm() {
      return this.terms?.find((i) => i.years === this.termsValue || i === this.termsValue);
    },
    validateParents() {
      return this.parentSelection === 'Yes' && this.parentsAges.length > 0;
    },
    computedDependents() {
      const dependents = [];

      // Add spouse dependents
      this.spouseArray.forEach((spouse, index) => {
        if (this.spouseSelection[index] === 'Yes') {
          dependents.push({
            depDesc: 'Spouse',
            depDOB: this.spouseAges[index],
          });
        }
      });

      // Add children
      this.children.forEach((val) => {
        if (val) {
          dependents.push({
            depDesc: 'Child',
            depDOB: val,
          });
        }
      });

      // Add parents
      this.parent.forEach((val, i) => {
        if (val) {
          dependents.push({
            depDesc: this.parentArray[i],
            depDOB: val,
          });
        }
      });
      return dependents;
    },
  },

  methods: {
    onCalculate() {
      this.form.name = `${this.form.surname} ${this.form.othernames}`;
      this.loading = true;
      const formData = {
        quoteCode: 0,
        quoteAgentCode: this.agentCode,
        clientCode: 0,
        totalContribution: 0,
        retireAge: 0,
        optionCode: 0,
        ...this.form,
        ...this.productData,
        sumAssured: parseInt(this.sumAssured, 10),
        premium: parseInt(this.premium, 10),
        dependents: this.computedDependents,
      };

      axios.post('/lmsagent/quotations/saveQuote', formData).then((res) => {
        const quotationResp = res.data;
        this.$store.commit('quote/clientInfo', quotationResp);
        this.$emit('to-quote-summary', quotationResp);
        this.loading = false;
      });
    },
    givenYearsAgo,
    age,
  },
};
</script>
