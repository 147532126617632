<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip active-class="false" :to="{ name: 'Products' }">Products </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'PumzishaPlan' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png">Pumzisha Plan</card-title>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
            :is="current"
            :productData="productData"
            :terms="terms"
            :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary"
          ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
// import { mapState } from 'vuex';
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/pumzisha/Quote.vue';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';
import BackButton from '../../components/BackButton';

export default {
  name: 'PumzishaPlan',
  metaInfo: {
    title: 'RFQ - Pumzisha Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2013105 },
      terms: [5, 10],
      ages: [
        { title: 'Age 23 - 29', value: '23-29' },
        { title: 'Age 30 - 34', value: '30-34' },
        { title: 'Age 35 - 39', value: '35-39' },
        { title: 'Age 40 - 44', value: '40-44' },
        { title: 'Age 45 - 49', value: '45-49' },
        { title: 'Age 50 - 54', value: '50-54' },
        { title: 'Age 55 - 59', value: '55-59' },
        { title: 'Age 60 - 64', value: '60-64' },
      ],
    };
  },
};
</script>
