import axios from 'axios';
import QuoteSummary from '@/components/rfq/QuoteSummary';

export default {
  computed: {
    computedProductOptions() {
      return this.productOptions.map((option) => ({
        optionCode: this.scanObject(option, 'optionCode', ''),
        optionName: this.scanObject(option, 'optionDesc', ''),
      }));
    },
  },
  mounted() {
    this.$root.$on('nav-back-to', (data) => {
      this.current = data.currentTab;
    });
  },
  methods: {
    toQuoteSummary(info) {
      this.quoteSummaryData = info;
      this.current = QuoteSummary;
    },
    fetchBenefits() {
      axios
        .get(
          `/lmsagent/products/findProductBenefits?productCode=${this.productData.prodCode}`,
        )
        .then((resp) => {
          if (this.scanObject(resp, 'data.success')) {
            this.benefits = resp.data.data.map((benefit) => ({
              coverProdCode: this.scanObject(benefit, 'prodCoverCode', ''),
              coverName: this.scanObject(benefit, 'prodCoverType.coverDesc', ''),
            }));
          }
        });
    },
    fetchProductOptions() {
      axios
        .get(
          `/lmsagent/products/findProductOptions?productCode=${this.productData.prodCode}`,
        )
        .then((resp) => {
          if (this.scanObject(resp, 'data.success')) {
            this.productOptions = resp.data.data;
          }
        });
    },
    fetchTerms(optionCode) {
      const productOption = this.productOptions.find((option) => option.optionCode === optionCode);
      let optionMinTerm = this.scanObject(productOption, 'optionMinTerm', this.termLimit.min);
      const optionMaxTerm = this.scanObject(productOption, 'optionMaxTerm', this.termLimit.max);
      const terms = [];
      while (optionMinTerm <= optionMaxTerm) {
        // eslint-disable-next-line no-plusplus
        terms.push(optionMinTerm++);
      }
      this.terms = terms;
    },
  },
};
